<app-banner [title]="title"></app-banner>

<section class="ftco-section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="wrapper">
          <div class="row no-gutters">
            <div
              class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch"
            >
              <div class="contact-wrap w-100 p-md-5 p-4">
                <h3 class="mb-4">Get in touch</h3>
                <div id="form-message-success" class="mb-4">
                  {{ responseMessage }}
                </div>
                <form
                  method="POST"
                  class="contactForm"
                  [formGroup]="contactForm"
                  (ngSubmit)="onSubmit()"
                  autocomplete="off"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="label">Full Name</label>
                        <input
                          type="text"
                          formControlName="name"
                          placeholder="Full Name"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.name.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.name.errors.required">
                            Full Name is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="label">Mobile No</label>
                        <input
                          type="text"
                          formControlName="mobile"
                          placeholder="Mobile No"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.mobile.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && f.mobile.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.mobile.errors.required">
                            Mobile no is required
                          </div>
                          <div *ngIf="f.mobile.errors.pattern">
                            Please, Enter 10 digit Mobile No.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="label">Email Address</label>
                        <input
                          type="text"
                          formControlName="email"
                          placeholder="Email Address"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.email.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && f.email.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.email.errors.required">
                            Email Address is required
                          </div>
                          <div *ngIf="f.email.errors.email">
                            Email must be a valid email address
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="label">Message</label>
                        <textarea
                          class="form-control"
                          formControlName="message"
                          cols="30"
                          rows="4"
                          placeholder="Message"
                          [ngClass]="{
                            'is-invalid': submitted && f.message.errors
                          }"
                        ></textarea>
                        <div
                          *ngIf="submitted && f.message.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.message.errors.required">
                            Message is required
                          </div>
                        </div>
                      </div>
                      <input
                        formControlName="honeypot"
                        class="d-none"
                        type="text"
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          value="Send Message"
                          class="btn btn-primary"
                        />
                        <div class="submitting"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-4 col-md-5 d-flex align-items-stretch">
              <div class="info-wrap bg-primary w-100 p-md-5 p-4">
                <h3>Let's get in touch</h3>
                <p class="mb-4">
                  We're open for any suggestion or just to have a chat
                </p>
                <div class="dbox w-100 d-flex align-items-start">
                  <div
                    class="
                      icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <span class="fa fa-map-marker"></span>
                  </div>
                  <div class="text pl-3">
                    <p>
                      <span>Address:</span> Katra Puran Jat, Near Gupta & Goyal
                      Nursing Home, Moradabad, Uttar Pradesh 244001
                    </p>
                  </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                  <div
                    class="
                      icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <span class="fa fa-phone"></span>
                  </div>
                  <div class="text pl-3">
                    <p><span>Phone: </span>0591 2974444</p>
                  </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                  <div
                    class="
                      icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <span class="fa fa-paper-plane"></span>
                  </div>
                  <div class="text pl-3">
                    <p>
                      <span>Email: </span>
                      <a href="mailto:contact@mkaccountify.com"
                        >contact&#64;mkaccountify.com</a
                      >
                    </p>
                  </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                  <div
                    class="
                      icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <span class="fa fa-globe"></span>
                  </div>
                  <div class="text pl-3">
                    <p>
                      <span>Location: </span>
                      <a
                        target="_blank"
                        href="https://www.google.com/maps/place/Mahesh+Kumar+Yadav+Accountant/@28.8392437,78.7715404,15z/data=!4m5!3m4!1s0x0:0xa877d194cba06ba9!8m2!3d28.8392573!4d78.7715369"
                        >Get Direction</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
