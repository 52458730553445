import { Component } from '@angular/core';
import {
  emailIdConstant,
  homeTitleConstant,
  linksData,
  phoneNumberConstant,
} from 'src/app/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent {
  title = homeTitleConstant;
  phoneNumber = phoneNumberConstant;
  emailId = emailIdConstant;
  links = linksData;

  constructor() {}

  ngOnInit(): void {}
}
